// 查询 - 个人信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;

// 查询 - 课程类型 - 列表
const getCourseTypeUrl = `/gateway/hc-edu/manageapi/schoolCourseType/getCourseTypeList`;

// 查询 - 所有课程 - 列表
const getAllCourseListUrl = `/gateway/hc-edu/miniapi/schoolCourse/getCourseApplyPageList`;

// 查询 - 课程 - 详情
const getCourseDetailUrl = `/gateway/hc-edu/miniapi/schoolCourse/getCourseInfoById`;

// 保存 - 课程 - 报名信息
const saveCourseApplyInfoUrl = `/gateway/hc-edu/miniapi/schoolCourseApply/addInfo`;

// 查询 - 我的课程 - 课程表 - 列表
const getMyCourseSheetListUrl = `/gateway/hc-edu/miniapi/schoolCourse/getCourseSchedulePageList`;

// 查询 - 我的课程 - 课程表 - 详情
const getMyCourseSheetDetailUrl = `/gateway/hc-edu/`;

// 查询 - 我的课程 - 课程表 - 场次考勤 - 列表
const getMyCourseSheetSessionKqListUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/getScheduleAttendanceList`;

// 保存 - 我的课程 - 课程表 - 场次考勤 - 信息
const saveMyCourseSheetSessionKqUrl = `/gateway/hc-edu/miniapi/schoolCourseScheduleAttendance/addOrUpdateInfo`;

// 查询 - 我的课程 - 课程订单 - 列表
const getMyCourseOrderListUrl = `/gateway/hc-edu/miniapi/schoolCourseApply/getApplyOrderPageList`;

// 查询 - 我的课程 - 课程订单 - 详情
const getMyCourseOrderDetailUrl = `/gateway/hc-edu/miniapi/schoolCourseApply/getApplyOrderInfo`;

// 取消 - 我的课程 - 课程订单 - 支付/报名
const cancelMyCourseOrderPayApplyUrl = `/gateway/hc-edu/miniapi/schoolCourseApply/cancelApplyOrder`;

// 查询 - 课程管理 - 列表
const getCourseManageListUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/getCourseListByUserAndDay`;

// 查询 - 课程管理 - 有课日期 - 列表
const getCourseManageHaveCouDatListUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/getHaveCourseDayList`;

// 查询 - 课程管理 - 课程情况 - 详情
const getCourseManageDetailUrl = `/gateway/hc-edu/12`;

// 查询 - 课程管理 - 课程情况 - 场次 - 列表
const getCouManScheListUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/getScheduleListByCourseId`;

// 查询 - 课程管理 - 课程情况 - 报名学生 - 列表
const getCouManAtteListUrl = `/gateway/hc-edu/miniapi/schoolCourseScheduleAttendance/getUserAttendanceList`;

// 查询 - 课程管理 - 课程情况 - 人员 - 列表
const getCoursePeopleUrl = `/gateway/hc-edu/13`;

// 取消 - 课程管理 - 课程情况 - 课程
const cancleCourseUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/cancelSchedule`;

// 改期 - 课程管理 - 课程情况 - 课程
const modifyCoursePeriodUrl = `/gateway/hc-edu/miniapi/schoolCourseSchedule/updateScheduleDateTime`;

// 取消 - 课程管理 - 课程情况 - 签到
const cancleCourseQdUrl = `/gateway/hc-edu/16`;

// 手动 - 课程管理 - 课程情况 - 签到
const handCourseQdUrl = `/gateway/hc-edu/17`;

// 操作 - 课程管理 - 课程情况 - 请假
const handCourseAskLeaveUrl = `/gateway/hc-edu/18`;

// 操作 - 课程管理 - 课程情况 - 销假
const handCourseCanLeaveUrl = `/gateway/hc-edu/19`;

// 查询 - 课程管理 - 报名情况 - 列表
const getCourseManageSignSitListUrl = `/gateway/hc-edu/miniapi/schoolCourseApply/getApplyPageListByCourseId`;

export {
  userInfoUrl,
  getCourseTypeUrl,
  getAllCourseListUrl,
  getCourseDetailUrl,
  saveCourseApplyInfoUrl,
  getMyCourseSheetListUrl,
  getMyCourseSheetDetailUrl,
  getMyCourseSheetSessionKqListUrl,
  saveMyCourseSheetSessionKqUrl,
  getMyCourseOrderListUrl,
  getMyCourseOrderDetailUrl,
  cancelMyCourseOrderPayApplyUrl,
  getCourseManageListUrl,
  getCourseManageHaveCouDatListUrl,
  getCouManScheListUrl,
  getCouManAtteListUrl,
  cancleCourseUrl,
  modifyCoursePeriodUrl,
  cancleCourseQdUrl,
  handCourseQdUrl,
  handCourseAskLeaveUrl,
  handCourseCanLeaveUrl,
  getCourseManageSignSitListUrl
};
